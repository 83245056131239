import { render, staticRenderFns } from "./WantWantMarking.vue?vue&type=template&id=7c6b9044&scoped=true&"
import script from "./WantWantMarking.vue?vue&type=script&lang=js&"
export * from "./WantWantMarking.vue?vue&type=script&lang=js&"
import style0 from "./WantWantMarking.vue?vue&type=style&index=0&id=7c6b9044&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c6b9044",
  null
  
)

export default component.exports