<template>
  <div>
    <div class="banner">
      <warning-tip code="WANGWANGDABIAO"></warning-tip>

      <main-top title="旺旺打标" desc="给指定的买家旺旺打标，强行打标后宝贝出现在手淘搜索页前20位，可实现宝贝精准推送的效果，补单、引流利器！" 
      liability="使用本网站此项功能的必须是经过旺旺买家号本人同意且授权后方可使用，使用本软件功能不得用于不正当竞争，若使用者用于不正当竞争或者其他其他商业用途，若使用产生的一切法律后果均由使用者承担，与本网站无关。" 
      spend="WANGWANGDABIAO"/>
      <auto-layout>
        <div class="detail-body">
          <bordure-row title="宝贝同款/分析" style="margin-bottom:30px;">
            <div class="multipleSearch">
              <div style="margin: 0 0 30px 0;text-align: center;">
                <el-radio-group v-model="mode" size="small">
                  <el-radio-button label="0" class="same_radio_diy">找相似</el-radio-button>
                  <el-radio-button label="1" class="same_radio_diy">找同款</el-radio-button>
                  <!-- <el-radio label="0" border>找相似</el-radio>
                <el-radio label="1" border>找同款</el-radio> -->
                </el-radio-group>
              </div>
              <el-form :inline="true" :model="formInSame" class="demo-form-inline" :rules="sameRules" ref="formInSame">
                <el-form-item prop="goodsId" style="width:calc(100% - 155px);">
                  <el-input v-model="formInSame.goodsId" style="width: 100%;" placeholder="请输入需要查询的宝贝链接/id">
                    <i class="el-icon-search el-input__icon" slot="prefix" style="font-size:16px;line-height:36px;"></i>
                  </el-input>
                </el-form-item>
                <el-button type="primary" @click="onSubmit('formInSame')" size="small" style="width:145px;">查询
                </el-button>
              </el-form>
            </div>
          </bordure-row>

          <!-- table表格 -->
          <bordure-row title="商品信息" style="margin-bottom:30px;" shadow>
            <el-table :data="tableSame" style="width: 100%" max-height="500" @selection-change="handleSelectionChange"  size="mini">
              <el-table-column type="selection" width="40"></el-table-column>
              <el-table-column prop="item_id" label="商品ID" align="center" header-align="center" width="150">
              </el-table-column>
              <el-table-column prop="title" label="商品名称" align="center" header-align="center" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="shop" label="商品店铺名" align="center" header-align="center" width="200">
              </el-table-column>
              <el-table-column label="商品图片" align="center" header-align="center" width="120">
                <template slot-scope="scope">
                  <img style="width: 70px; height: 70px" :src="formatterPic(scope.row.pic_path)" alt />
                </template>
              </el-table-column>
              <el-table-column prop="price" label="价格" align="center" width="100" sortable header-align="center">
              </el-table-column>
            </el-table>
          </bordure-row>
        </div>

        <!-- 表头输入框 -->
        <bordure-row title="旺旺打标" class="detail-body">
          <div class="multipleSearch">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" :rules="rules" ref="formInline2">
              <el-form-item prop="goodsId" style="width:100%;">
                <span slot="label">宝贝ID/宝贝链接:</span>
                <el-input v-model="formInline.goodsId"  
                  :disabled="multipleSelection.length>0?true:false" placeholder="请输入宝贝ID或宝贝链接">
                </el-input>
              </el-form-item>
              <el-form-item label="目标关键词:" prop="searchKey"  style="width:100%">
                <el-input v-model="formInline.searchKey" placeholder="请输入目标关键词" style="width: 100%"></el-input>
              </el-form-item>
              <el-form-item label="淘宝账号:" prop="taoBaoList" style="width:100%;">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10 }"
                  placeholder="请输入账号淘宝账号，可用逗号或回车分隔多个账号" v-model="formInline.taoBaoList">
                </el-input>
              </el-form-item>
            </el-form>
            <div style="text-align: center;">
              <el-button type="primary" @click="startMarkingFn('formInline2')" size="small" style="width: 150px;" :disabled="!CanYouClick">
                开始打标
              </el-button>
            </div>
          </div>
        </bordure-row>
      </auto-layout>


      <!-- 打标历史记录 -->
      <div class="tableTitle">打标历史记录</div>
      <el-table :data="tableData"  size="mini" style="width: 100%; border: 1px solid #e5e5e5" :header-cell-style="{
          background: '#f8f8f8',
          'font-family': 'PingFangSC-Regular',
          'font-size': '14px',
          color: '#666666 !important',
          'letter-spacing': '0',
          'text-align': 'center',
          'font-weight': 'normal',
        }">
        <el-table-column align="center" prop="createTime" label="时间" width="250"></el-table-column>
        <el-table-column align="center" prop="keyword" label="搜索关键字"></el-table-column>
        <el-table-column align="center" prop="goodsId" label="商品id"></el-table-column>
        <el-table-column align="center" prop="account" label="账号"></el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <span :class="scope.row.status == 2 ? 'success' : 'danger'">
              {{ scope.row.status == 2 ? "成功" : "失败" }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 讲解文案区域 -->
      <Introduce :introduce="introduce"></Introduce>
    </div>
  </div>
</template>
<script>
  
  import {
    wangWangMarking,
    getMarkingList,
    queryExoGoodsList
  } from "../../request/api"; //这里是引入请求
  import Introduce from "../public/Introduce";
  export default {
    data() {
      return {
        CanYouClick: true,
        // 基本信息data
        formInline: {
          goodsId: "",
          searchKey: "",
          taoBaoList: "",
        },
        //表单的校验规则
        rules: {
          goodsId: [{
              required: true,
              message: "请输入宝贝ID或宝贝链接",
              trigger: "blur",
            },
            {
              min: 5,
              message: "请输入正确的宝贝ID或宝贝链接",
              trigger: "blur"
            },
          ],
          searchKey: [{
              required: true,
              message: "请输入目标关键词",
              trigger: "blur"
            },
            {
              min: 1,
              message: "请输入正确的目标关键词",
              trigger: "blur"
            },
          ],
          taoBaoList: [{
              required: true,
              message: "请输入淘宝账号",
              trigger: "blur"
            },
            {
              min: 1,
              message: "请输入正确的淘宝账号",
              trigger: "blur"
            },
          ],
        },
        taoBaoList: [{
          value: ""
        }, {
          value: ""
        }, {
          value: ""
        }],
        tableData: [{
            account: "102kg（模拟数据）",
            createTime: "2021-04-13 11:56:18（模拟数据）",
            goodsId: "611633442015（模拟数据）",
            id: 1,
            keyword: "棉麻（模拟数据）",
            status: 1,
            userId: 61,
          },
          {
            account: "102kg（模拟数据）",
            createTime: "2021-04-13 11:56:18（模拟数据）",
            goodsId: "611633442015（模拟数据）",
            id: 1,
            keyword: "棉麻（模拟数据）",
            status: 2,
            userId: 61,
          },
        ],


        formInSame: {
          goodsId: "",
          searchKey: "",
        },
        tableSame: [],
        //表单的校验规则
        sameRules: {
          goodsId: [{
              required: true,
              message: "请输入宝贝ID或宝贝链接",
              trigger: "blur",
            },
            {
              min: 5,
              message: "请输入正确的宝贝ID或宝贝链接",
              trigger: "blur"
            },
          ],
        },
        totalPage: 1, //初始页
        pageSize: 20, //    每页的数据
        totalCount: 10, //表格总数
        // radio: "1", //单选框
        mode: "1", //单选框
        multipleSelection: [],

        introduce: [{
            title: "打标后使用的效果",
            icon: "&#xe60a;",
            content: [
              "给指定的买家旺旺打标，强行打标后宝贝出现在手淘搜索页前20位，可实现宝贝精准推送的效果，补单、引流利器！",
              "只需输入要打标的宝贝链接，以及要推送宝贝的目标买家旺旺账号。执行打标后，打标成功的特征：买家通过打标词搜索商品，能将打标的商品排名提高到前20名。期间不需要买家旺旺号介入任何话流程",
            ],
          },
          {
            title: "打标的使用场景",
            icon: "&#xe60c;",
            content: [
              "1.商品核心词权重低搜不到，但是想通过核心词搜索下单成交",
              "2.目标淘宝旺旺被打标成功增加首页猜你喜欢标签注入、加大淘宝首页出现概率",
              "3.精准推送给意向客户之前有咨询没付款的，使用工具后将宝贝推送到他们的搜索页",
              "4.对于复购率很高的消耗品，列出老客户或者精准客户旺旺清单，然后批量推送，快速引爆流量",
            ],
          },
          {
            title: "打标常见问题",
            icon: "&#xe60b;",
            content: [
              "1.商品核心词权重低搜不到，但是想通过核心词搜索下单成交",
              "2.如果通过打标词在前10位商品未找到，可以在前20位商品寻找，如果仍然未找到请打开淘宝足迹确定是否出现打标商品，如果为出现请联系客服",
              "3.有时被打标的宝贝位置可能在20页开外，可以往下翻多几页，或者改长点的词，该商品在该打标词权重太低",
              "4.并非所有类目宝贝能成功打标，店铺评分低，成人用品或降权的情况下可能无法成功打标即使打标成功也无打标效果",
              "5.打标成功的有效时长根据淘宝更新时间，打标前可以用自己的号测试看下效果，再进行批量打标",
              "6.同一个淘宝号当日被打标次数超过一定的限度，会降低打标后的效果，建议旺旺号不易在一天超过10次打标",
            ],
          },
        ], //介绍相关数组
      };
    },
    components: {
      Introduce,
    },
    created() {
      if (this.$store.state.token != null) {
        this.getDataList();
      }
    },
    methods: {
      // 获取数据列表
      addTaoBaoFn() {
        this.taoBaoList.push({
          value: ""
        });
      },
      //新添加的自动计划表
      startMarkingFn(formName) {
        this.formInline.taoBaoList = this.formInline.taoBaoList.replace(
          /[.\\[\]{}(),，|^$?*+]/g,
          ","
        );
        // 表单验证的方法;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let inputGoodsId = new String(this.formInline.goodsId) || "";
            inputGoodsId = inputGoodsId.trim();
            let goodsId = '';
            if (!isNaN(inputGoodsId)) {
                goodsId = inputGoodsId;
            } else {
              goodsId = this.$comm.getUrlParam(inputGoodsId, 'id')
            }
            
            let needIntegral = this.$store.state.modelIntegralMap.get("WANGWANGDABIAO");
            let accountStrs = this.formInline.taoBaoList.replace(/\s*\r?\n/g, ",");
            let accountList = accountStrs.split(",");
            let totalIntegral = accountList.length * needIntegral;
            this.$confirm("打标 <b>" + accountList.length + "</b> 个淘宝账号，需要消耗<b style='color:red;'> "+totalIntegral+"</b> 积分,是否继续？",{
              type:"warning",
              dangerouslyUseHTMLString:true
            }).then(async () => {
              //执行打标
              this.$store.commit("alterJumpFlag", true);
              this.$store.commit("alterLoadContent", "正在进行旺旺打标");
              this.CanYouClick = false;
              try{
                for(let i = 0; i < accountList.length; i++){
                  let account = accountList[i];
                  await wangWangMarking({
                      account: account,
                      key: this.formInline.searchKey,
                      itemid: goodsId,
                    });
                }
              }finally{
                this.$store.commit("alterJumpFlag", false); //关掉遮罩层
                this.CanYouClick = true;
                this.getDataList();
              }
              
            });


          } else {
            console.log("error 失败了");
            return false;
          }
        });
      },
      //获取列表信息
      getDataList() {
        getMarkingList().then((data) => {
          this.tableData = data.data;
        }).finally(() => {
          this.$store.commit("alterJumpFlag", false);
        });
      },


      // 同款\相似分析
      //   改变单选框时触发
      changeRadio(value) {
        this.onSubmit("formInline");
      },
      //点击查询
      onSubmit(formName) {
        //表单验证的方法
        this.$refs[formName].validate((valid) => {
          //判断是否有填正确的id
          if (valid) {
            let inputGoodsId = new String(this.formInSame.goodsId) || "";
            inputGoodsId = inputGoodsId.trim();
            let goodsId = '';
            if (!isNaN(inputGoodsId)) {
                goodsId = inputGoodsId;
            } else {
              goodsId = this.$comm.getUrlParam(inputGoodsId, 'id')
            }
            this.$store.commit("alterJumpFlag", true);
            this.$store.commit("alterLoadContent", "正在搜索宝贝同款/相似分析");
            this.tableSame = []
            this.expands = [];
            queryExoGoodsList({
              itemid: goodsId, //宝贝id  609427481266
              mode: this.mode, //搜索模式， 0 - 搜索相似商品 1 - 搜索同款商品
              page_num: this.totalPage, // 页码  "1"
              page_size: this.pageSize, // 每页数据条数，最大值 "20"
              sort: 1, //排序方式，1-综合排序,2-新品排序,3-销量由高到低,4-销量由低到高,5-价格由高到低,6-价格由低到高,7-信用由高到低,8-信用由低到高
              keyword: 0, //关键词  "0"
            }).then((data) => {
              this.tableSame = data.data.exoGoods.ItemsList;
              
            }).finally(() => {
              this.$store.commit("alterJumpFlag", false); //关掉遮罩层
            });
            console.log(this.formInSame);
          } else {
            console.log("error 失败了");
            return false;
          }
        });
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
        let arr = this.multipleSelection.map(item => {
          return item.item_id
        })
        console.log(arr)
        this.formInline.goodsId = arr.toString()
      },
      formatterPic(picUrl){
        if(picUrl){
            picUrl = picUrl.replace(/^http(s)?:/, "");
            //淘宝新图片地址处理
            let oldDomain = "g.search.alicdn.com";
            let newDomain = "img.alicdn.com";
            picUrl = picUrl.replace(oldDomain, newDomain);
        }

        
        
        return picUrl;
      },





    },
  };
</script>

<style lang="less" scoped>
  .multipleSearch {
    margin: 0 auto;
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 16px 31px;
    box-sizing: border-box;

    /deep/.el-form-item__content{
      width:100%;
      line-height: 36px;
    }

    /deep/.el-form-item__label,
    /deep/.el-input--small,
    /deep/.el-input__inner {
      height: 36px;
      line-height: 36px;
      box-sizing: border-box;
    }

    /deep/.el-form-item__error {
      color: #f70000;
      letter-spacing: 0;
    }

    .addBtn {
      background: #5d7cff;
      border: 1px solid #5d7cff;
      border-radius: 4px;
      box-sizing: border-box;
      height: 36px;
      line-height: 36px;
      color: #fff;
      margin-left: 10px;
      padding: 0 16px;
    }

    .addBtn:hover {
      background-color: #5572e8 !important;
    }
  }

  .multipleSearchBottom {
    padding: 0 80px 100px;
    text-align: center;
  }

  .detail-body{
    margin-bottom:30px;
    width: 48%;

  }

  @media screen and (max-width: 640px){
    .detail-body{
      width: 100%;
    }
  }

</style>